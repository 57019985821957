import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const guardacustodiaPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Guarda y Custodia" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Guarda y Custodia"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Guarda y Custodia"
      >
        <p>
        El fundamento legal lo encontramos en el libro I artículo 166 del Código Civil de Guatemala.
        </p>        
        <p>
        Los padres podrán convenir a quién de ellos se confían los hijos; pero el juez, por causas
        graves y motivadas, puede resolver en forma distinta, tomando en cuenta el bienestar de los
        hijos. Podrá también el juez resolver sobre la custodia y cuidado de los menores, con base
        en estudios o informes de trabajadores sociales o de organismos especializados en la protección
        de menores. En todo caso, cuidará de que los padres puedan comunicarse libremente con ellos.
          </p>
          <p>
          ¡¡¡Llámenos y haga su cita hoy!!!  El licenciado Erick Claveria le atenderá gustosamente.
            </p>         
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default guardacustodiaPage

